import React, { useEffect, useState } from "react";
import { Button } from "../../components";
import { ModalBank } from ".";
import * as yup from "yup";
import * as loader from "components/Loading/loading";
import API from "api/Api";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Cache from "utils/cache";
import { NumericFormat } from "react-number-format";
import ModalUnauthorized from "./ModalUnauthorized";
import { useNavigate } from "react-router-dom";

const schema = yup
	.object({
		amount: yup.number().typeError('You must specify a number').min(0, 'Min value 0.'),
	})
	.required();
const Form = (props) => {
	const navigate = useNavigate();
	const [isOpen, setIsOpen] = useState(false);
	const [isOpenUnauthorized, setIsOpenUnauthorized] = useState(false);
	const handleCloseModal = () => {
		API.post(`transactions`, dataSubmit).then(e => {
			loader.hideLoader();
			const platform = Cache.getData()?.platform || '';
			const currentUrl = window.location.href;
			const token = Cache.getData()?.token;
			if (platform === 'app') {
				sendDataToReactNative(e.data.transaction_data_id);
			} else {
				const redirectUrl = currentUrl.includes('stage')
					? `https://stage-fe.aix-exchange.com/wallet-donation?transaction_id=${ e.data.transaction_data_id }&xtoken=${ encodeURIComponent(token) }`
					: `https://aix.asia/wallet-donation?transaction_id=${ e.data.transaction_data_id }&xtoken=${ encodeURIComponent(token) }`;

				window.location.href = redirectUrl;
			}
			setIsOpen(false);
			setTimeout(() => {
				navigate(`/transactions/?platform=${ platform }&xtoken=${ encodeURIComponent(token) }`);
			}, 5000);
		}).catch(e => {
			loader.hideLoader();
			setIsOpen(false);
			setErrorMessage('Change a few things up and try submitting again.');
		});
	};
	const [errorMessage, setErrorMessage] = useState('');
	const [dataSubmit, setDataSubmit] = useState({});
	const [amount, setAmount] = useState('');
	const { register, handleSubmit, formState: { errors }, setValue } = useForm({
		resolver: yupResolver(schema),
	});

	const onSubmit = (data) => {
		onSubmitDonation(data);
	};

	const onSubmitDonation = (data) => {
		if (Cache.getUser() === null) {
			localStorage['flash'] = 'Please login to continue';
			setIsOpenUnauthorized(true);
			return;
		}
		if (Cache.getUser() !== null && Cache.getUser().hasBinded2FA === false) {
			setErrorMessage('You need to Binded 2FA first in AIX Profile');
			return;
		}
		if ((props.balance && amount > props.balance) || !props.balance) {
			setErrorMessage('Insufficient balance. Please top up your balance to proceed.');
			return;
		}
		data['payment_method'] = 'crypto';
		data['payment_type'] = 'USDT';
		data['charity_id'] = props.charity.id;
		data['charity_name'] = props.charity.charity_name;
		data['institution_id'] = props.charity.institution_id;
		data['institution_name'] = props.charity.institution_name;
		data['institution_address'] = props.institutions.address;
		data['amount'] = amount;
		data['fee_amount'] = parseFloat(props.fee);
		setDataSubmit(data);
		setIsOpen(true);
	};
	const handleAmountChange = (e) => {
		const amount = parseFloat(e.target.value.replace(/,/g, ''));
		setAmount(amount);
	};

	useEffect(() => {
		const user = Cache.getUser();
		if (user != null) {
			setValue('donatur_name', user.fullname);
			setValue('donatur_email', user.email);
			setValue('donatur_phone', user.phone);
		}
		// bankAPI();
	}, []);

	const sendDataToReactNative = (data) => {
		window.ReactNativeWebView.postMessage(data);
	};
	const handleModalUnauthorized = () => {
		const currentUrl = window.location.href;
		const platform = Cache.getData()?.platform;
		if (platform === 'app') {
			window.ReactNativeWebView.postMessage("closeWebView");
		} else {
			const redirectUrl = currentUrl.includes('stage')
				? 'https://stage-fe.aix-exchange.com/'
				: 'https://aix.asia/';

			window.location.href = redirectUrl;
		}
	};

	return (
		<div className="flex flex-col border border-blue_gray-100 rounded-md p-5 mx-10">
			<div className="flex-col flex items-center gap-2 mb-5">
				<p className="!font-bold !text-lg text-center">Niat Zakat</p>
				<p className=" text-center">نَوَيْتُ أَنْ أُخْرِجَ زَكاَةَ اْلمَالِ عَنْ نَفْسِيْ فَرْضًا لِلهِ تَعَالَى</p>
				<p className=" text-center">"Nawaytu an ukhrija zakaata maali fardhoon Lillaahi Ta'aala."</p>
				<p className=" text-center">"Aku niat mengeluarkan zakat hartaku, fardhu karena Allah Ta'aala"</p>
			</div>
			<p className="!font-bold !text-lg !font-shipporimincho text-center mb-2">Enter the Nominal Donation</p>
			<div className="flex flex-col items-center justify-center gap-3 w-full">
				<form onSubmit={ handleSubmit(onSubmit) } className="flex flex-col items-center justify-start w-full mt-[3px] gap-3">
					<div className="flex flex-col items-start justify-start w-full gap-[7px]">
						<div
							className='flex items-center justify-center h-[65px] gap-2 pl-[22px] pr-[35px] text-base border border-blue_gray-100 rounded-md  text-gray-500 w-full'
						>
							<NumericFormat name="amount" allowNegative={ false } type="tel" label="Amount" displayType={ 'input' } thousandSeparator={ true } placeholder="Input Contribution*" className="w-full h-[40px]" { ...register('amount') }
								error={ errors?.amount?.message } onInput={ (e) => handleAmountChange(e) } />
							USDT
						</div>
						{ errors?.amount?.message !== "" && <p className="text-red-500 text-xs italic w-full">{ errors?.amount?.message }</p> }
					</div>
					{ errorMessage !== '' && <div className="flex items-center p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400" role="alert">
						<svg className="flex-shrink-0 inline w-4 h-4 me-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
							<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
						</svg>
						<span className="sr-only">Info</span>
						<div className="ml-2 mr-2">
							<span className="font-medium">Error!</span>  { errorMessage }
						</div>
					</div>
					}
					<Button
						type='submit'
						size="sm"
						disabled={ !amount }
						className="gap-2 rounded-[28px] whitespace-nowrap !bg-green-200 !text-base px-14 h-[40px]"
					>
						Confirm
					</Button>
				</form>
			</div>
			<ModalBank isOpen={ isOpen } amount={ amount } onButtonClick={ handleCloseModal } onCancelClick={ () => setIsOpen(false) } />
			<ModalUnauthorized isOpen={ isOpenUnauthorized } onButtonClick={ handleModalUnauthorized } onCancelClick={ () => setIsOpenUnauthorized(false) } />
		</div>
	);
};

export default Form;